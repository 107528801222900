h1, h2, h3 {
	margin-top: 0;

	i.h--i {
		display: inline-block;
		text-align: center;
		margin-right: $space-content * 0.5
	}
}
h2 i.h--i {
	width: 36px;
}
h3 i.h--i {
	width: 29px;
}

time {
	font-size: $font-size-large;
	display: block;
	margin-bottom: $space-content * 0.5;
}

.text--no-break {
	white-space: nowrap;
}

.text--center {
	text-align: center;

	margin: auto;
	width: 100%;
	@media(min-width: $screen-xs-min) {
		width: 80%
	}
	@media(min-width: $screen-sm-min) {
		width: 60%;
	}
	@media(min-width: $screen-md-min) {
		width: 50%;
	}
}

.text--warning {
	display: block;
	color: $brand-warning;
}

.content {
	padding-top: $space-content;
	padding-bottom: $space-content;

	p, .ul--text {
		font-family: $font-family-serif;
		font-weight: 200;
	}
	p {
		font-size: $font-size-large;
	}
	b {
		font-weight: 500;
	}
}