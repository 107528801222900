.video {
	position: relative;
	.video__spinner {
		position: absolute;
		z-index: 2;
		top: 0; right: 0; bottom: 0; left: 0;
		@extend .anim--fade-out;
		@extend .center--horizontal;
		@extend .center--vertical;
		overflow: hidden;
		i {
			color: $brand-primary;
			font-size: 90px;
		}
	}
	&.video--loading .video__spinner {
		@extend .anim--fade-in;
	}

	.video__fallback {
		display: block;
		width: 100%;
		height: auto;
	}

	video {
		display: block;
		position: absolute;
		z-index: 1;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
	}
}



.headspace {
	position: relative;
	overflow: hidden;

	&--full {
		height: 100vh;
	}

	&__logo {
		// background: transparent url(../../media/logo-white.svg) no-repeat center;
		background-size: contain;
		display: inline-block;
		height: 50vh;
		width: 50vh;
		margin: auto;
	}

	&__img {
		position: absolute;
		top: $header-height; right: 0; bottom: 0; left: 0;
		z-index: -2;
		background-size: cover;
		//background-attachment: fixed;
		//background-position: center;
	}
	&__video {
		position: absolute;
		top: $header-height; right: 0; bottom: 0; left: 0;
		z-index: -1;

		.video {
			width: 100%;
			height: 100%;
			.video__spinner i {
				font-size: 150px;
			}
			video {
				object-fit: cover;
			}
		}
	}

	&__darkener {
		padding-top: $header-height;
		height: 100%;
		width: 100%;
		
		background-color: $transp-darker;
		display: flex;
	  	align-items: center;
		justify-content: center;
	}

	&__inner {
		text-align: center;
		display: inline-block;
		color: #fff;
	}
}

.no-headspace {
	height: $header-height;
	background-color: $body-bg;
}