body {
	overflow-x: hidden;
}


.headspace, .content, footer {
	@extend .anim--swoop-in;
}
body.router-page-mounting {
	.headspace, .content, footer {
		@extend .anim--swoop-up;
	}
}
body.router-page-unmount {
	.headspace, .content, footer {
		@extend .anim--swoop-up;
	}
}


.wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.noscript {
	min-height: 100vh;
	.box {
		max-width: 400px;
	}
}

aside {
	position: relative;
	&.sticky {
		@media(min-width: $screen-md-min) {
			position: sticky !important;
			top: $header-height + $space-content;	
		}
	}
	z-index: 1;
}

.content {
	flex: 1;
	padding-top: $space-content;
}

textarea {
	resize: vertical;
	min-height: 112px;
}

.invisible {
	visibility: hidden;
}

.eye {
	max-width: 900px;
	position: relative;
	z-index: -1;


	margin: -20px auto;
	@media(min-width: $screen-xs-min) {
		margin: -80px auto;
	}
	@media(min-width: $screen-sm-min) {
		margin: -120px auto;
	}
	@media(min-width: $screen-md-min) {
		margin: -160px auto;
	}

	&__back, &__front {
		width: 100%;
		height: auto;
	}
	&__back {
		position: absolute;
		z-index: -2;
		top: 0;
		left: 0;
	}
}

