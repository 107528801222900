code {
	border-radius: 0;
	padding: 0;
}
.code {
	position: relative;
	&__lang {
		position: absolute;
		top: 0;
		right: 0;
		padding: 2px 4px;
		border: 1px solid #ccc;
		border-bottom-left-radius: $border-radius-small;
		border-top-right-radius: $border-radius-small;
		background-color: $brand-primary;
	}
}

.code-demo {
	border-radius: $border-radius-small;
	border: 1px solid #ccc;
	padding: $space-content $space-content * 0.5;
	margin-bottom: $space-content;
	margin-top: $space-content * 0.5;
	background-color: rgb(40, 44, 52); // same as Code Component
}

.tldr {
	display: flex;
	gap: $space-content;
	margin-bottom: $space-content;

	strong {
		font-size: $font-size-large;
		font-family: $font-family-sans-serif;
		flex: 1 1 0;
	}

	&__content {
		flex: 1 1 100%;
	}

}

article.project {
	.ribbon, .code, .ul--text {
		margin-top: $space-content;
		margin-bottom: $space-content;
	}
	& > section {
		.Collapsible {
			margin-top: $space-content;
			.code:last-child {
				margin-bottom: 0;
			}
		}
		margin-bottom: 2 * $space-content;
	}
}
#similar-projects {
	margin-bottom: $space-content;
}