
.space-content {
	margin-bottom: $space-content;
	&:last-child {
		margin-bottom: 0;
	}
}

.space-content--negative {
	margin-top: -$space-content;
}

.space-content--half {
	display: block;
	margin-bottom: $space-content * 0.5;
	&:last-child {
		margin-bottom: 0;
	}
}
.space-content--double {
	display: block;
	margin-bottom: $space-content * 2.0;
	&:last-child {
		margin-bottom: 0;
	}
}