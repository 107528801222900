
//noinspection ALL
.bttn {
	@extend .box;
	@extend .bttn--primary;
	@extend .bttn--md;

	display: inline-block;
	padding: 0;

	margin: 0;
	

	&__i {
		height: 100%;
		float: left;
		display: flex;
		align-items: center;
        justify-content: center;
	}
	&__content {
		height: 100%;
		display: flex;
		align-items: center;
        justify-content: center;
	}

	& > *:first-child {
		border-top-left-radius: $box-border-radius;
		border-bottom-left-radius: $box-border-radius;
	}
	& > *:last-child {
		border-top-right-radius: $box-border-radius;
		border-bottom-right-radius: $box-border-radius;
	}

	// ##################################################

	&--bordered {
		box-shadow: inset 0px 0px 4px 4px rgba(0,0,0,0.18), inset 0px 0px 8px 4px rgba(0,0,0,0.18) !important;
	}

	// ##################################################

	&--block {
		display: block;
	}

	// ##################################################

	&.bttn--block {
		margin-bottom: $space-content * 0.5;
		margin-right: 0;
		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}
	& {
		margin-bottom: 0;
		margin-right: $space-content * 0.5;
		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	// ##################################################

	transition: color $anim-speed-fade linear;
	& .bttn__content, & .bttn__i {
		transition: background-color $anim-speed-fade linear;
	}
	&:not(.bttn--disabled):hover {
		@media (hover: hover) {
			.fa {
				animation-name: fa-bounce;
			    animation-delay: var(--fa-animation-delay, 0s);
			    animation-direction: var(--fa-animation-direction, normal);
			    animation-duration: var(--fa-animation-duration, 1s);
			    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			    animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
			}
		}
	}

	// ##################################################

	@each $size in map-keys($btn-sizes) {
		$sizes: map-get($btn-sizes, $size);

		$height: map-get($sizes, "height");
		$icon-container-width: map-get($sizes, "icon-container-width");
		$icon-size: map-get($sizes, "icon-size");
		$border-size: map-get($sizes, "border-size");
		$border-radius: map-get($sizes, "border-radius");
		$font-size: map-get($sizes, "font-size");

		&--#{$size} {
			height: $height;
			& .bttn__i {
				width: $icon-container-width;
				font-size: $icon-size;
			}
			& .bttn__content {
				padding: 0 $space-content * 0.5;
				font-size: $font-size;
			}
			&.bttn--bordered {
				padding: $border-size;
				height: $height + 2 * $border-size;
				border-radius: $border-radius !important;
			}
		}
	}



	// ##################################################

	@each $name, $colors in $btn-colors {
        &--#{$name} {
            &, &:link, &:visited, &:focus, &:active {
                color: map-get($colors, "color") !important;
            }

            & .bttn__i {
                background-color: map-get($colors, "icon-bg");
            }
            & .bttn__content {
                background-color: map-get($colors, "bg");
            }

            &:hover, &.bttn--active {
                color: map-get($colors, "color-active") !important;

                & .bttn__i {
                    background-color: map-get($colors, "icon-bg-active");
                }
                & .bttn__content {
                    background-color: map-get($colors, "bg-active");
                }
            }
        }
    }

	// ##################################################

}