$anim-speed-base: 0.2s;
$anim-speed-fade: 0.14s;
$anim-speed-fade--long: 0.28s;
$anim-speed-move: 0.5s;

$anim-delay-time: 0.11s;

.anim {
	&--fade {
		&-in {
			visibility: visible;
			opacity: 1.0;
			pointer-events: inherit;
			transition:
					visibility 0s linear 0s,
					opacity $anim-speed-fade linear 0s;
		}
		&-out {
			visibility: hidden;
			opacity: 0.0;
			pointer-events: none;
			transition:
					visibility 0s linear $anim-speed-fade,
					opacity $anim-speed-fade linear 0s;
		}
	}

	&--swoop {
		&-in {
			@extend .anim--fade-in;
			transform: translate(0, 0);
			transition: transform $anim-speed-fade linear 0s,
				visibility 0s linear 0s,
				opacity $anim-speed-fade linear 0s;
			&--long {
				transition: transform $anim-speed-fade--long ease-out 0s,
					visibility 0s ease-out 0s,
					opacity $anim-speed-fade--long ease-out 0s;
			}
			@for $i from 0 to 9 {
	        	&.anim--delay--#{$i} {
	        		$delay-time: $i * $anim-delay-time;
	        		transition: transform $anim-speed-fade--long linear $delay-time,
						visibility 0s linear $delay-time,
						opacity $anim-speed-fade--long linear $delay-time !important;
	        	}
	        }
		}

		&-out {
			@extend .anim--fade-out;
			
			&--long {
				transition: transform $anim-speed-fade--long ease-out 0s,
					visibility 0s ease-out $anim-speed-fade--long,
					opacity $anim-speed-fade--long ease-out 0s;
			}
			@for $i from 0 to 9 {
	        	&.anim--delay--#{$i} {
	        		$delay-time: $i * $anim-delay-time;
	        		transition: transform $anim-speed-fade--long linear $delay-time,
						visibility 0s linear $anim-speed-fade--long + $delay-time,
						opacity $anim-speed-fade--long linear $delay-time !important;
	        	}
	        }
		}
	}
	&--swoop {
		&-up {
			@extend .anim--swoop-out;
			&--long { @extend .anim--swoop-out--long; }
			transform: translate(0, -120px);
		}

		&-right {
			@extend .anim--swoop-out;
			&--long { @extend .anim--swoop-out--long; }
			transform: translate(120px, 0);
		}

		&-left {
			@extend .anim--swoop-out;
			&--long { @extend .anim--swoop-out--long; }
			transform: translate(-120px, 0);
		}

		&-down {
			@extend .anim--swoop-out;
			&--long { @extend .anim--swoop-out--long; }
			transform: translate(0, 120px);
		}
	}

	&--blink-dark {
		animation: blink-dark 1.6s ease-in-out 0s infinite normal;
	}
}



@keyframes blink-dark {
	000% { background-color: rgba($transp-dark, 0.0); }
	050% { background-color: $transp-dark; }
	100% { background-color: rgba($transp-dark, 0.0); }
}