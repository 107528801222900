.form-group {
	position: relative;
	overflow: hidden;
}
.invalid-tooltip {
	@extend .anim--swoop-right;

	background-color: $brand-danger;
	padding: 1px 4px;
	border-radius: $border-radius-small;
	position: absolute;
	top: 0;
	right: 0;
	margin: auto;

	&:before {
		@extend .fa-solid;
		@extend .fa-triangle-exclamation;
		margin-right: 4px;
	}
}
.invalid-tooltip.invalid-tooltip--active {
	@extend .anim--swoop-in;
}


form > * {
	position: relative;
}
form .form__success {
	position: absolute;
	top: 0; left: 0;
	height: 100%; width: 100%;

	background-color: $body-bg-secondary;

	@extend .anim--fade-out;

	i {
		font-size: 5em;
		color: $brand-primary;
		transform: scale(0.0);
		opacity: 0.0;
	}
}
form.form--success .form__success {
	@extend .anim--fade-in;

	i {
		transition: transform 0.3s cubic-bezier(.38,1,.76,.98) 0.2s,
			opacity 0.2s cubic-bezier(.38,1,.76,.98) 0.15s;
		
		transform: scale(1.0);
		opacity: 1.0;
	}
}