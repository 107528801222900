.inpt {
	display: inline-block;
	position: relative;

	@extend .inpt--md;
	@extend .inpt--primary;

	&__i {
		position: absolute;
		top: 0;
		&--left { left: 0; }
		&--right { right: 0; }
		height: 100%;

		display: flex;
		align-items: center;
        justify-content: center;

        pointer-events: none;
	}

	input {
		border: 1px solid black;
	}

	// ##################################################

	@each $size in map-keys($inpt-sizes) {
		$sizes: map-get($inpt-sizes, $size);

		$height: map-get($sizes, "height");
		$icon-container-width: map-get($sizes, "icon-container-width");
		$icon-size: map-get($sizes, "icon-size");
		$border-radius: map-get($sizes, "border-radius");

		&--#{$size} {
			height: $height;
			& .inpt__i {
				width: $icon-container-width;
				font-size: $icon-size;

				&--left {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}
				&--right {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
			& input {
				height: $height;
				border-radius: $border-radius;
			}
		}
	}

	// ##################################################

	@each $col in map-keys($inpt-colors) {
		$colors: map-get($inpt-colors, $col);

		$bg: map-get($colors, "bg");
		$bg-active: map-get($colors, "bg-active");
		$color: map-get($colors, "color");
		$color-active: map-get($colors, "color-active");
		$icon-bg: map-get($colors, "icon-bg");
		$icon-bg-active: map-get($colors, "icon-bg-active");
		$icon-color: map-get($colors, "icon-color");
		$icon-color-active: map-get($colors, "icon-color-active");

		&--#{$col} {
			& .inpt__i {
				background-color: $icon-bg;
				color: $icon-color;
			}
			& input {
				background-color: $bg;
				color: $color;
			}
		}
	}
}