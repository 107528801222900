
.ul--tag-list {
	list-style-type: none;
	padding-left: 0;
	line-height: 2;
	li {
		display: inline-block;
		margin-right: 6px;
	}
}

a.tag {
	&, i {
		border-radius: 6px;
	}
	background-color: black;
	white-space: nowrap;

	font-weight: 400;
	font-size: $font-size-base;
	font-family: $font-family-sans-serif;

	padding: 2px 5px;

	&:link, &:visited {
		color: white;
	}
	&:hover, &:active, &:focus {
		color: white;
	}

	position: relative;
	overflow: hidden;
	i {
		display: block;
		position: absolute;
		z-index: 1;
		top: 0; right: 0; bottom: 0; left: 0;
		opacity: 0;
		transition: opacity $anim-speed-fade linear;
	}

	&:hover i {
		opacity: 1;
	}
}

.tag {
	&__content {
		position: relative;
		z-index: 2;
		display: inline-block;
	}
	&__separator {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		width: 1px;
		background-color: rgba(#fff, 0.4);
		line-height: 1.2;
	}
}