// ##################################################
// TODO: use bootstrap vars

$space-content: 22px;
$grid-gutter-width: 22px;

// ##################################################

$brand-primary: #7a31be;
$brand-secondary: #21b0bd;
$brand-tertiary: #554069;

$primary-text:			$brand-primary;
$primary-text-dark:		darken($brand-primary, 15%);
$primary-text-light:	lighten($brand-primary, 15%);
$secondary-text:		$brand-secondary;
$secondary-text-dark:	darken($brand-secondary, 15%);

$transp-dark:           rgba(#000, 0.3);
$transp-darker:         rgba(#000, 0.6);

$color-contrast-dark:	#000;
$color-contrast-light:	#fff;

$link-color:			$primary-text;
$link-hover-color:		$secondary-text;
$link-hover-decoration:	none;

// ##################################################

$body-bg:               #222222;
$body-bg-secondary:     #111111;
$text-color:            #eeeeee;

// ##################################################

$font-family-sans-serif:  "Dosis", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "RobotoSlab", Georgia, "Times New Roman", Times, serif;

$font-size-base:         15px;
$font-size-nav:          ceil(($font-size-base * 1.15));
//$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-larger:         ceil(($font-size-base * 1.35)); // ~18px
//$font-size-small:         ceil(($font-size-base * .85)); // ~12px
$font-size-h1:            floor(($font-size-base * 2.6));
$font-size-h2:            floor(($font-size-base * 1.98));
$font-size-h3:            floor(($font-size-base * 1.42));

$headings-font-weight:    600;

// ##################################################
$code-color:                  #bbb;
$code-bg:                     transparent;
// ##################################################

$input-bg:                       $body-bg;
$input-bg-disabled:              $body-bg;

$input-color:                    $color-contrast-light;
$input-border:                   lighten($body-bg, 10%);

$input-border-focus:             $brand-primary;

$form-group-margin-bottom:		 $space-content * 0.5;

//$input-color-placeholder:        #999;

// ##################################################

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$container-sm: (720px + $grid-gutter-width);
$container-md: (940px + $grid-gutter-width);
$container-lg: (1140px + $grid-gutter-width);

