$box-padding: $padding-large-horizontal;
$box-border-radius: $border-radius-large;


.on-scroll-in {
	display: flex;
	justify-content: center;
	flex-direction: column;
}


.box {
	position: relative;
	display: block;
	border-radius: $box-border-radius;
	background-color: $body-bg-secondary;
	margin-bottom: $space-content;
	@extend .shadow;
	&::after {
		border-radius: $box-border-radius;
	}

	& > h1, & > h2, & > h3, & > table {
		margin-bottom: $box-padding;
		margin-top: 0;
	}
	& > *:last-child {
		margin-bottom: 0;
	}

	// ##################################################

	&__colored {
		&--top {
			border-top-left-radius: $box-border-radius;
			border-top-right-radius: $box-border-radius;
		}
		&--bottom {
			border-bottom-left-radius: $box-border-radius;
			border-bottom-right-radius: $box-border-radius;
		}
	}
	&__colored--solid {
		transition: background-color $anim-speed-fade linear;
		h1, h2, h3 {
			margin-top: 0;
			// text-transform: uppercase;
			color: $color-contrast-light;
			margin-bottom: 0;
		}
	}

	&__colored--solid {
		background-color: $brand-primary;
		
		padding: ($box-padding * 0.5) $box-padding;
		//margin-bottom: -$box-padding;
	}


	// ##################################################

	&__img-container {
		background-color: $body-bg;
		height: 10em;
		&--xxl {
			height: 26em;
		}
		&--xl {
			height: 18em;
		}
		&--lg {
			height: 12em;
		}
		&--sm {
			height: 8em;
		}
		overflow: hidden;
	}
	&__img-container--top {
		border-top-left-radius: $box-border-radius;
		border-top-right-radius: $box-border-radius;
	}
	&__img-container--bottom {
		border-bottom-left-radius: $box-border-radius;
		border-bottom-right-radius: $box-border-radius;
	}
	& .img {
		height: 100%;
		width: 100%;
		img {
			object-fit: cover;
			object-position: center;
		}
		.img__preload {
			height: 100%;
		}
	}

	// ##################################################

	&__img-container {
		position: relative;
	}
	&__img-title {
		position: absolute;
		left: 0; right: 0; bottom: 0;
		text-align: center;
		font-size: $font-size-large;
		color: $color-contrast-light;
		background-color: $transp-darker;
		padding: $space-content * 0.25;
	}

	// ##################################################

	&__content {
		padding: $box-padding;
		&--no-space {
			overflow: hidden;
			border-radius: $box-border-radius;
			padding: 0;
		}
	}

	// ##################################################

	&--link {
		@extend .shadow--hoverable;
		cursor: pointer;

		p {
			color: $text-color;
		}

		transition: transform $anim-speed-fade linear;
		transform: translateY(0);
		&:hover {
			transform: translateY(-2px);
		}

		.img {
			transition: transform 1.2s ease-in-out;
		}
		&:hover .img {
			transform: scale(1.08);
		}

		.box__img-title {
			opacity: 0;
			transform: translateY(30px);
			transition: transform $anim-speed-fade linear, opacity $anim-speed-fade linear;
		}
		&:hover .box__img-title {
			opacity: 1;
			transform: translateY(1px);
		}

		&:hover .box__colored--top {
			background-color: $brand-secondary;
		}
	}


	// ##################################################

	
	&.Collapsible {
		.Collapsible__trigger {
			&:hover {
				background-color: $brand-secondary;
			}
		}
	}
}

.Collapsible {
	overflow: hidden;
	.Collapsible__trigger {
		cursor: pointer;
		user-select: none;

		.Collapsible__collapse {
			float: right;
			@extend .fa;
			@extend .fa-angles-down;

			transition: transform $anim-speed-fade linear;
		}

		&.Collapsible__trigger--hide .Collapsible__collapse {
			transition: transform $anim-speed-fade linear, opacity $anim-speed-fade linear;	
		}

		&.is-closed .Collapsible__collapse {
			transform: scaleY(1.0);
		}
		&.is-open .Collapsible__collapse {
			transform: scaleY(-1.0);
		}
		&.is-closed.Collapsible__trigger--hide .Collapsible__collapse {
			transform: scaleY(1.0);
			opacity: 1;
		}
		&.is-open.Collapsible__trigger--hide .Collapsible__collapse {
			transform: scaleY(1.0);
			opacity: 0;
		}
	}
}

.read-more {
	$btn-height: map-get(map-get($btn-sizes, "lg"), "height");
	padding-bottom: $btn-height;

	.Collapsible__trigger {
		height: 0;
	}
	.Collapsible__contentOuter {
		margin-bottom: -1 * $btn-height;
	}
}
