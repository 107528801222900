$grid-breakpoints : (
				xs: $screen-xs-min,
				sm: $screen-sm-min,
				md: $screen-md-min,
				lg: $screen-lg-min
);

// ##################################################

$header-height: 50px;

// ##################################################

$nav-bar-bg:			$brand-primary;
$nav-bar-bg-active:		$brand-secondary;
$nav-bar-color:			#fff;
$nav-bar-color-active:	#fff;

$nav-bg:				$body-bg;
$nav-bg-active:			$brand-primary;
$nav-color:				#fff;
$nav-color-active:		#fff;

// ##################################################
$btn-sizes: (
	md : ( // Default must be first
		height:						48px,
		icon-container-width:		56px,
		icon-size:					22px,
		border-size:				6px,
		border-radius:				9px,
		font-size:					$font-size-large,
	),
	sm : (
		height:						42px,
		icon-container-width:		42px,
		icon-size:					20px,
		border-size:				4px,
		border-radius:				7px,
		font-size:					$font-size-base,
	),
	lg : (
		height:						58px,
		icon-container-width:		64px,
		icon-size:					28px,
		border-size:				8px,
		border-radius:				11px,
		font-size:					$font-size-larger
	)
);

$btn-colors: (
	primary : (
		bg:							darken($brand-primary, 0%),
		bg-active:					darken($brand-secondary, 0%),
		icon-bg:					darken($brand-primary, 6%),
		icon-bg-active:				darken($brand-secondary, 6%),
		color:						#fff,
		color-active:				#fff
	),
	secondary : (
		bg:							darken($brand-secondary, 0%),
		bg-active:					darken($brand-secondary, 6%),
		icon-bg:					darken($brand-secondary, 4%),
		icon-bg-active:				darken($brand-secondary, 10%),
		color:						#fff,
		color-active:				#fff
	),
	disabled : (
		bg:							darken(#666, 0%),
		bg-active:					darken(#666, 0%),
		icon-bg:					darken(#666, 6%),
		icon-bg-active:				darken(#666, 6%),
		color:						#fff,
		color-active:				#fff
	),
	transparent : (
		bg:							rgba(#000,  0%),
		bg-active:					rgba(#000,  2%),
		icon-bg:					rgba(#000,  4%),
		icon-bg-active:				rgba(#000,  6%),
		color:						#000,
		color-active:				#000
	)
);

// ##################################################

$inpt-sizes: (
	md : ( // Default must be first
		height:						1.9em,
		icon-container-width:		2.0em,
		icon-size:					1.2em,
		border-radius:				5px
	),
	sm : (
		height:						1.5em,
		icon-container-width:		1.8em,
		icon-size:					1.0em,
		border-radius:				4px
	),
	lg : (
		height:						2.3em,
		icon-container-width:		2.1em,
		icon-size:					1.4em,
		border-radius:				6px
	)
);

$inpt-colors: (
	primary : (
		bg:							darken(#fff0,  0%),
		bg-active:					darken(#fff0,  4%),
		color:						inherit,
		color-active:				inherit,
		icon-bg:					darken($brand-primary, 0%),
		icon-bg-active:				darken($brand-primary, 4%),
		icon-color:					#fff,
		icon-color-active:			#fff
	),
	secondary : (
		bg:							darken(#fff,  0%),
		bg-active:					darken(#fff,  4%),
		color:						inherit,
		color-active:				inherit,
		icon-bg:					darken($brand-secondary, 0%),
		icon-bg-active:				darken($brand-secondary, 4%),
		icon-color:					#fff,
		icon-color-active:			#fff
	),
	transparent : (
		bg:							darken(#0000,  0%),
		bg-active:					darken(#0000,  4%),
		color:						inherit,
		color-active:				inherit,
		icon-bg:					darken(#0000, 2%),
		icon-bg-active:				darken(#0000, 6%),
		icon-color:					#000,
		icon-color-active:			#000
	)
);

// ##################################################

$footer-height:			43px;
$footer-bg:				$body-bg-secondary;
$footer-bg-active:		$body-bg;
$footer-color:			$color-contrast-light;
$footer-color-active:	$primary-text;

// ##################################################

$fa-font-path: "../fonts";

// ##################################################