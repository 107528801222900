
.hidden-md {
	&-down {
		@media (max-width: map-get($grid-breakpoints, "md")) {
			display: none !important;
		}
	}
	&-up {
		@media (min-width: map-get($grid-breakpoints, "md")) {
			display: none !important;
		}
	}
}

.clearfloat {
	clear: both;	
}
.clearfix {
	&--xs, &--sm, &--md, &--lg, &--sm-md {
		@extend .clearfix;
	}
	&--xs {
		display: block;
		@media (min-width: map-get($grid-breakpoints, "sm")) {
			display: none;
		}
	}
	&--sm {
		display: none;
		@media (min-width: map-get($grid-breakpoints, "sm")) {
			display: block;
		}
		@media (min-width: map-get($grid-breakpoints, "md")) {
			display: none;
		}
	}
	&--md {
		display: none;
		@media (min-width: map-get($grid-breakpoints, "md")) {
			display: block;
		}
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			display: none;
		}
	}
	&--sm-md {
		display: none;
		@media (min-width: map-get($grid-breakpoints, "sm")) {
			display: block;
		}
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			display: none;
		}
	}
	&--lg {
		display: none;
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			display: block;
		}
	}
}


.center--vertical {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.center--horizontal {
	display: flex;
	align-items: center;
	justify-content: center;
}