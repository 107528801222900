header {
	position: fixed;
	z-index: 101;
	height: $header-height;
	width: 100%;
	font-size: $font-size-nav;

	&:before {
		content: "";
		position: absolute;
		top: 0; left: 0; width: 100%; height: 100%;
		z-index: 100;
		pointer-events: none;
		@extend .shadow;
	}
}


// ##################################################

.logo {
	display: inline-block;
  	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

header.header--collapsible .logo {
	background-position: left center;
}

// ##################################################

header:not(.header--collapsible) nav {
	@extend .container;
}
nav ul {
	@extend .ul--link-list--horizontal;
	display: block;
}
nav li {
	line-height: $header-height;
	transition: background-color $anim-speed-fade linear;
}


// ##################################################

nav li {
	.logo {
		//background-image: url(../../media/logo-white.svg);
	}
	& > a {
		position: relative;
		padding: 0 $space-content;
		z-index: 30;
		&:link, &:visited {
			color: $nav-bar-color;
		}
		&:hover, &:active {
			color: $nav-bar-color-active;
		}
	}
	background-color: transparent;
	&:hover {
		background-color: $nav-bar-bg-active;
		& > a {
			color: $nav-bar-color-active;
		}
	}
}

header.header--collapsible {
	nav {
		overflow: hidden;
		pointer-events: none;
	}
	&.header--open nav {
		pointer-events: auto;
	}
	.header__btn {
		display: flex;
	}
	.logo {
		background-position: left center;
	}
	ul {
		@extend .shadow;
		@extend .ul--link-list--vertical;
		@extend .anim--swoop-up;
		border-bottom: 6px solid $brand-primary;
	}
	&.header--open ul {
		@extend .anim--swoop-in;
	}

	nav li {
		position: relative;
		@extend .container;

		.logo {
			//background-image: url(../../media/logo-gray.svg);
		}
		&:hover .logo {
			//background-image: url(../../media/logo-white.svg);
		}

		background-color: rgba($nav-bg, 0.97);
		&:hover {
			background-color: rgba($nav-bg-active, 0.97);
		}
	}

	nav a {
		&:link, &:visited {
			color: $nav-color;
		}
		&:hover, &:active {
			color: $nav-color-active;
		}
	}
}

// ##################################################

.header__btn {
	display: none;
	height: 100%;
	position: relative;
	z-index: 30;
	align-items: center;
	justify-content: flex-start;
}

// ##################################################

.header__background {
	height: 100%;
	width: 100%;
	position: relative;
	background-color: $nav-bar-bg;
	z-index: 10;	
}

.header__canvas {
	position: absolute;
	z-index: 20;
	left: 0; top: 0;
	width: 100%; height: 100%;
}
.shader-rect {
	height: 100%;
	width: 100%;
}