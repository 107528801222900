footer {
	background-color: $footer-bg;
	color: $footer-color;

	a:link, a:visited {
		color: $footer-color;
	}
	a:hover, a:active, a:focus {
		color: $footer-color-active;
	}

	.footer__left, .footer__right {
		@extend .ul--simple;
		display: block;
		line-height: $footer-height;
		.li--fa {
			position: relative;
			top: -2px;
		}
		& > li {
			display: inline-block;
			margin-left: $space-content * 0.5;
			&.li--fa {
				margin-left: $space-content * 0.25;
			}
			&:first-child, &.li--fa:first-child {
				margin-left: 0;
			}
		}
	}
	.footer__left {
		float: left;
	}
	.footer__right {
		float: right;
	}

	@media (max-width: map-get($grid-breakpoints, sm)) {
		.footer__left, .footer__right {
			float: none;
		}
		.footer__left {
			border-bottom: 1px solid $footer-bg-active;
		}
	}

	@media (max-width: map-get($grid-breakpoints, xs)) {
		font-size: $font-size-small;
	}
}
