$vertical-padding: $space-content;

.ribbon {
	&--primary:after {
		background-color: rgba(darken($brand-primary, 35%), 50%);
	}
	&--secondary:after {
		background-color: $brand-secondary;
	}
	&--tertiary:after {
		background-color: $brand-tertiary;
	}

	&--body-secondary:after {
		background-color: $body-bg-secondary;
	}
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	

	left: calc(-0.5 * (100vw - 100%));
	width: 100vw;
	padding: $vertical-padding calc(0.5 * (100vw - 100%));
}

.has-sidebar .ribbon {
	@media(min-width: $screen-md-min) {
		$sidebar: $container-md * 0.3333333;

		left: calc(-1*$sidebar - (100vw - (100% + $sidebar)) * 0.5);
		padding:
			$vertical-padding
			calc((100vw - (100% + $sidebar)) * 0.5)
			$vertical-padding
			calc($sidebar + (100vw - (100% + $sidebar)) * 0.5);
	}
	@media(min-width: $screen-lg-min) {
		$sidebar: $container-lg * 0.25;

		left: calc(-1*$sidebar - (100vw - (100% + $sidebar)) * 0.5);
		padding:
			$vertical-padding
			calc((100vw - (100% + $sidebar)) * 0.5)
			$vertical-padding
			calc($sidebar + (100vw - (100% + $sidebar)) * 0.5);
	}
}