$ul--link-list-padding: 10px;

ul {
	padding-left: 1.0rem;
	margin-bottom: 0;
}

// ##################################################

.ul--simple {
	display: block;
	padding-left: 0;
	& > li {
		list-style-type: none;
	}
}

.ul--common {
	@extend .ul--simple;
}

// ##################################################

.ul--link-list {
	a {
		display: block;
		height: 100%;
		padding: 0 $ul--link-list-padding;
		text-decoration: none;
		font-weight: 600;
	}
}

.ul--link-list--horizontal {
	@extend .ul--common;
	@extend .ul--link-list;
	& > li {
		display: inline-block;
	}
}

.ul--link-list--vertical {
	@extend .ul--common;
	@extend .ul--link-list;
	& > li {
		display: block;
	}
}

// ##################################################

.ul--icon-list {
	list-style-type: none;
	font-size: $font-size-large;
	li {
		line-height: 2;
	}
	.fa {
		color: $brand-primary;
		min-width: 38px;
		margin-right: 12px;
		text-align: center;
	}
	li > a {
		&, .fa {
			transition: color $anim-speed-fade linear;
		}
		&:link, &:visited {
			color: $color-contrast-light;
			.fa {
				color: $brand-primary;
			}
		}
		&:hover, &:active, &:focus {
			&, .fa {
				color: $brand-secondary;
			}
		}
	}
}

// ##################################################

.ul--toc {
	@extend .ul--simple;
	font-size: $font-size-large;
	line-height: 1.3;

	a {
		display: block;
		position: relative;
	}

	span {
		display: inline-block;
		transform: translateX(0);
		transition: transform $anim-speed-fade linear;
	}

	i {
		display: inline-block;
		height: 100%;
		position: absolute;
		left: 0;
		top: 3px;

		opacity: 0;
		transition: opacity $anim-speed-fade linear;
	}

	a:hover {
		span {
			transform: translateX(20px);
		}
		i {
			opacity: 1;
		}
	}
}

// ##################################################

.ul--text {
	font-family: $font-family-serif;
	font-size: $font-size-large;
	list-style-type: none;
	padding-left: 3rem;
	li {
		&:before {
			content: "";
			display: inline-block;
			height: 1.2rem;
			width: 1.2rem;
			margin-left: -3rem;
			margin-right: 1.8rem;
			background: transparent url("../../media/static/bullet.png") no-repeat;
			background-size: contain;
		}
	}
}

// ##################################################


.li--fa {
	@extend .fa-stack;
	@extend .fa-1x;
	width: 2.0em;
	&__bg {
		@extend .fa-regular;
		@extend .fa-circle;
		@extend .fa-stack-2x;

		transform: scale(1.0);
		transition: transform 0.3s ease-in;
	}
	&__icon {
		@extend .fab;
		@extend .fa-stack-1x;
		@extend .fa-inverse;

		transform: scale(1.1);
		transition: transform 0.3s ease-out;
	}
	a:hover .li--fa__icon {
		transform: scale(1.4);
	}
	a:hover .li--fa__bg {
		transform: scale(1.08);
	}

	&--github {
		.li--fa__bg {color: #000000;}
		.li--fa__icon {@extend .fa-github}
	}
	&--linkedin {
		.li--fa__bg {color: #0A66C2;}
		.li--fa__icon {@extend .fa-linkedin}
	}
	&--twitter {
		.li--fa__bg {color: #74B0D1;}
		.li--fa__icon {@extend .fa-twitter}
	}
	&--google {
		.li--fa__bg {color: #4285f4;}
		.li--fa__icon {@extend .fa-google}
	}
	&--facebook {
		.li--fa__bg {color: #435E99;}
		.li--fa__icon {@extend .fa-facebook-f}
	}
	&--instagram {
		.li--fa__bg:before {
			color: transparent;
			background: #d6249f;
			background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
			border-radius: 20px;
		}
		.li--fa__icon {
			@extend .fa-instagram;
			font-size: 1.3em;
		}
	}
}



// ##################################################



