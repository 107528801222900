@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-Regular.ttf");
	font-weight: normal;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-ExtraLight.ttf");
	font-weight: 200;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-Medium.ttf");
	font-weight: 500;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-Bold.ttf");
	font-weight: 700;
}
@font-face {
	font-family: "Dosis";
	src: url("../../media/fonts/Dosis/Dosis-ExtraBold.ttf");
	font-weight: 800;
}

@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-Regular.ttf");
	font-weight: normal;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-ExtraLight.ttf");
	font-weight: 200;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-Medium.ttf");
	font-weight: 500;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-Bold.ttf");
	font-weight: 700;
}
@font-face {
	font-family: "RobotoSlab";
	src: url("../../media/fonts/RobotoSlab/RobotoSlab-ExtraBold.ttf");
	font-weight: 800;
}